<template>
  <!-- отчеты для проживания и активного отдыха -->
  <v-container id="details" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" title="Отчеты" class="px-3 py-3" style="position: relative">
      <v-row class="mt-3 ml-3" :style="[
        width > 480
          ? { width: '50%', position: 'absolute', top: '10px', right: '30px' }
          : {}
      ]">
        <v-text-field label="Поиск - По ФИО, номер бронирования" v-model="searchText" @change="selectFilter" />
        <v-btn class="ml-2" fab small>
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </v-row>
      <!-- активный отдых -->
      <v-data-table v-if="object_type == 'is_leisure'" :headers="headers" :items="items" item-key="name"
        :loading="loading" :class="width > 480 ? 'mt-8' : ''" loading-text="Идёт загрузка данных..."
        :options.sync="options" :server-items-length="totalItems">
        <template v-slot:[`item.comment`]="{ item }">
          {{ computedItem(item.comment) }}
        </template>
        <template v-slot:top>
          <v-row>
            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
              <v-menu ref="from" v-model="fromDateDialog" :close-on-content-click="false" :return-value.sync="from"
                transition="scale-transition" offset-y min-width="290px" max-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="computedFrom" label="С:" readonly v-bind="attrs"
                    prepend-icon="mdi-calendar-blank-multiple" v-on="on" />
                </template>
                <v-date-picker v-model="from" no-title scrollable locale="ru" :first-day-of-week="1">
                  <v-spacer />
                  <v-btn text color="primary" @click="fromDateDialog = false">
                    Отмена
                  </v-btn>
                  <v-btn text color="primary" @click="saveFrom">
                    Применить
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
              <v-menu ref="to" v-model="toDateDialog" :close-on-content-click="false" :return-value.sync="to"
                transition="scale-transition" offset-y min-width="290px" max-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="computedTo" label="По:" readonly v-bind="attrs"
                    prepend-icon="mdi-calendar-blank-multiple" v-on="on" />
                </template>
                <v-date-picker v-model="to" no-title scrollable locale="ru" :first-day-of-week="1">
                  <v-spacer />
                  <v-btn text color="primary" @click="toDateDialog = false">
                    Отмена
                  </v-btn>
                  <v-btn text color="primary" @click="
                    getUpdReport();
                  toDateDialog = false;
                  $refs.to.save(to);
                  ">
                    Применить
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="type == 'order'">
              <v-select v-model="filter" label="Фильтр" :items="filters" @change="selectFilter" />
            </v-col>
            <v-col>
              <v-select v-model="type" label="Тип отчета" :items="typesUpd" @change="selectFilter" />
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <!-- проживание -->
      <v-data-table v-else :headers="headers" :items="items" item-key="name" :loading="loading"
        :class="width > 480 ? 'mt-8' : ''" loading-text="Идёт загрузка данных..." :options.sync="options"
        :server-items-length="totalItems">
        <template v-slot:top>
          <v-row>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4">
              <v-menu ref="from" v-model="fromDateDialog" :close-on-content-click="false" :return-value.sync="from"
                transition="scale-transition" offset-y min-width="290px" max-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="computedFrom" label="С:" readonly v-bind="attrs"
                    prepend-icon="mdi-calendar-blank-multiple" v-on="on" />
                </template>
                <v-date-picker v-model="from" no-title scrollable locale="ru" :first-day-of-week="1">
                  <v-spacer />
                  <v-btn text color="primary" @click="fromDateDialog = false">
                    Отмена
                  </v-btn>
                  <v-btn text color="primary" @click="saveFrom">
                    Применить
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4">
              <v-menu ref="to" v-model="toDateDialog" :close-on-content-click="false" :return-value.sync="to"
                transition="scale-transition" offset-y min-width="290px" max-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="computedTo" label="По:" readonly v-bind="attrs"
                    prepend-icon="mdi-calendar-blank-multiple" v-on="on" />
                </template>
                <v-date-picker v-model="to" no-title scrollable locale="ru" :first-day-of-week="1">
                  <v-spacer />
                  <v-btn text color="primary" @click="toDateDialog = false">
                    Отмена
                  </v-btn>
                  <v-btn text color="primary" @click="
                    getReport();
                  toDateDialog = false;
                  $refs.to.save(to);
                  ">
                    Применить
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="type === 'source'">
              <v-select v-model="payStatus" label="Статус оплаты" :items="payStatuses" @change="selectFilter" />
            </v-col>

            <v-col v-if="isshow(type)">
              <v-select v-model="status" label="Статус" :items="statuses" @change="selectFilter" />
            </v-col>
            <v-col>
              <v-select v-model="type" label="Виды отчетов" :items="types" @change="selectFilter" />
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <div v-if="getUserStatus != 'PREMIUM' && this.getSuperUser != 'true'" class="d-flex">
        <p>Вам доступно только одна строка отчета, для повышения статуса обратитесь к админу.</p>
      </div>

      <!-- сумма -->
      <div style="display: flex; justify-content: space-between">
        <h3 class="ml-2" style="display: inline-block" v-if="type !== 'key'">
          <v-col v-if="total_data.total != null">Сумма: {{ total_data.total }}</v-col>
          <v-col v-if="total_data.paid != null">Оплатили: {{ total_data.paid }}</v-col>
          <v-col v-if="total_data.duty != null">Осталось оплатить: {{ total_data.duty }}</v-col>
          <v-col v-if="total_data.returned != null">Возвращено: {{ total_data.returned }}</v-col>

          <v-col v-if="total_data.breakfast_adult != null">
            Завтрак взрослые: {{ total_data.breakfast_adult }}
          </v-col>
          <v-col v-if="total_data.breakfast_child != null">
            Завтрак дети: {{ total_data.breakfast_child }}
          </v-col>
          <v-col v-if="total_data.lunch_adult != null">
            Обед взрослые: {{ total_data.lunch_adult }}
          </v-col>
          <v-col v-if="total_data.lunch_child != null">
            Обед дети: {{ total_data.lunch_child }}</v-col>
          <v-col v-if="total_data.supper_adult != null">
            Ужин взрослые: {{ total_data.supper_adult }}
          </v-col>
          <v-col v-if="total_data.supper_child != null">
            Ужин дети: {{ total_data.supper_child }}
          </v-col>
        </h3>
        <v-btn @click="download()" large color="primary" outlined class="mr-6">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24" class="mr-1">
            <path fill="currentColor"
              d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20M10,13H7V11H10V13M14,13H11V11H14V13M10,16H7V14H10V16M14,16H11V14H14V16M10,19H7V17H10V19M14,19H11V17H14V19Z" />
          </svg>
          Скачать
        </v-btn>
      </div>
    </base-material-card>
    <div class="py-3" />
  </v-container>
</template>

<script>
import moment from "moment";
import toast from "../../../plugins/toast";
import { ReportApiUrls } from "@/services/accommodationRequests/reports.api.js";
import user from "../../../store/auth";
export default {
  data() {
    return {
      width: 0, // width of screen
      totalItems: 0, // максимальное количество вытянутых полей
      options: {}, // пагинация
      firstWatcherTrigger: false, // проверка если первый запрос прошел уже
      type: "order", // вид отчетов
      searchText: "", // search
      object_type: "", // вид компании = { is_leisure | accommodation }
      total_data: {}, // сумма
      from: new Date().toISOString().substr(0, 10),
      to: new Date(moment(new Date()).add(1, "day"))
        .toISOString()
        .substr(0, 10),
      payStatus: "all", // Статус оплаты
      payStatuses: [
        { text: "Все", value: "all" },
        { text: "Оплаченные", value: "paid" },
        { text: "Неоплаченные", value: "not_paid" },
        { text: "Частично оплаченные", value: "partial" }
      ],
      status: "all", // Статус
      statuses: [
        { text: "Все", value: "all" },
        { text: "Заезды", value: "check_in" },
        { text: "Выезды", value: "check_out" },
        { text: "Проживания", value: "accommodation" },
        { text: "Отмена", value: "canceled" }
      ],
      fromDateDialog: false,
      toDateDialog: false,
      types: [
        { text: "Список оплат", value: "pay", class: "table-title" },
        { text: "По клиентам", value: "client", class: "table-title" },
        { text: "По номерам", value: "object", class: "table-title" },
        { text: "По загрузке", value: "object-zag", class: "table-title" },
        { text: "По броням", value: "order", class: "table-title" },
        { text: "По источникам", value: "source", class: "table-title" },
        { text: "По услугам", value: "service", class: "table-title" },
        { text: "По кухне", value: "kitchen", class: "table-title" },
        { text: "По замкам", value: "key", class: "table-title" },
        { text: "По талонам", value: "talon", class: "table-title" }
      ],
      typesUpd: [
        { text: "По броням", value: "order", class: "table-title" },
        { text: "По тарифам", value: "tarif", class: "table-title" }
      ],
      filter: "created", // Фильтр
      filters: [
        { text: "По созданию", value: "created", class: "table-title" },
        { text: "По выполнению", value: "check_in", class: "table-title" }
      ],
      headers: [],
      items: [],
      loading: false
    };
  },
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        if (this.object_type == "is_leisure") {
          if (this.type == "order") this.getUpdReport();
        } else this.getReport();
      },
      deep: true
    },
    items: {
      handler(newValue) {
        // Если пользователь является суперюзером, не ограничиваем items
        if (localStorage.getItem("is_superuser") === "true") {
          return;
        }

        // Проверяем статус пользователя
        if (this.getUserStatus !== "PREMIUM") {
          // Если newValue — массив и содержит элементы
          if (Array.isArray(newValue) && newValue.length > 0) {
            // Проверяем, изменился ли массив, чтобы избежать бесконечного цикла
            if (this.items.length !== 1 || this.items[0] !== newValue[0]) {
              this.items = [newValue[0]];
            }
          }
        }
      },
      deep: true, // Наблюдаем за изменениями внутри массива
    },
  },
  computed: {
    getUserStatus() {
      return user.getters.getUserStatus;
    },
    getSuperUser(){
      return user.getters.getIsSuperuser
    },
    computedFrom() {
      return moment(this.from).format("DD.MM.YYYY");
    },
    computedTo() {
      return moment(this.to).format("DD.MM.YYYY");
    }
  },
  created() {
    this.object_type = window.localStorage.getItem("object_type");
    if (this.object_type == "is_leisure") {
      this.type = "order";
      this.getUpdReport();
    } else {
      this.type = "pay";
      this.getReport();
    }
    this.width = window.innerWidth;
  },
  methods: {
    computedItem(item) {
      if (item) return item;
      else return "-";
    },
    isshow(type) {
      return (
        type !== "pay" &&
        type !== "object" &&
        type !== "object-zag" &&
        type !== "source" &&
        type !== "order" &&
        type !== "service" &&
        type !== "kitchen" &&
        type !== "key" &&
        type !== "talon"
      );
    },
    // скачать excel
    async download() {
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10,
        start: new Date(this.from).getTime() / 1000,
        end: new Date(this.to).getTime() / 1000,
        search: this.searchText
      };

      if (this.type == "client") params.booking_status = this.status;
      if (this.type === "source") params.payment_status = this.payStatus;
      if (this.type === "order" && this.object_type == "is_leisure")
        params.filter_by = this.filter;

      let obj = {
        pay: "export-payment",
        client: "export-client",
        order: "export-booking",
        object: "export-room",
        "object-zag": "export-room-workload",
        source: "export-source",
        service: "export-service",
        order:
          this.object_type == "is_leisure"
            ? "export-leisure-booking"
            : "export-booking",
        tarif: "export-leisure-tariff",
        kitchen: "export-kitchen-v2",
        key: "export-key",
        talon: "export-talon"
      };

      if (this.type === "talon") {
        delete params.search;
      }

      let res = await ReportApiUrls.getExcel(obj[this.type], params);
      console.log(res);
      var blob = new Blob([res], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment"
      });
      if (typeof window.navigator.msSaveBlob !== "undefined") {
        window.navigator.msSaveBlob(blob, "report.xlsx");
      } else {
        if (window.innerHeight < 500 || window.innerWidth < 500) {
          var ios =
            !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
          var isSafari =
            navigator.vendor &&
            navigator.vendor.indexOf("Apple") > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf("CriOS") == -1 &&
            navigator.userAgent.indexOf("FxiOS") == -1;
          if (ios && !isSafari) {
            toast.error("Вы можете скачать отчеты в Safari");
          } else {
            var reader = new FileReader();
            reader.fileName = "report.xlsx";
            var out = new File([res], "report.xlsx", {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment; filename='report.xlsx'"
            });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          }
        } else {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "report.xlsx");
          if (typeof link.download === "undefined") {
            link.setAttribute("target", "_blank");
          }
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }, 100);
        }
      }
    },
    selectFilter() {
      this.options.page = 1;
      if (this.object_type == "is_leisure") {
        this.getUpdReport();
      } else {
        this.getReport();
      }
    },
    async getTotal() {
      let params = {
        start: new Date(this.from).getTime() / 1000,
        end: new Date(this.to).getTime() / 1000,
        search: this.searchText
      };
      if (this.type == "client") params.booking_status = this.status;
      if (this.type === "source") params.payment_status = this.payStatus;

      let obj = {
        pay: "payment",
        client: "client",
        order: "booking",
        object: "room",
        "object-zag": "room",
        source: "source",
        service: "service",
        kitchen: "room",
        talon: "talon"
      };
      if (!obj[this.type]) return;
      if (this.type != "kitchen") {
        let res = await ReportApiUrls.getTotal(obj[this.type], params);
        this.total_data = res;
      } else {
        let response = await ReportApiUrls.getKitchenData(params);
        this.total_data = { ...this.total_data, ...response };
      }
    },
    async getUpdTotal() {
      let params = {
        start: new Date(this.from).getTime() / 1000,
        end: new Date(this.to).getTime() / 1000,
        search: this.searchText
      };
      let type;
      if (this.type == "order") {
        type = "leisure-booking-total";
        params.filter_by = this.filter;
      } else type = "leisure-tariff-total";

      let res = await ReportApiUrls.getLeisureTotal(type, params);
      this.total_data = res;
    },
    getName(data) {
      return data.firstName + " " + data.lastName + " " + data.middle_name;
    },
    async getUpdReport() {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10,
        start: new Date(this.from).getTime() / 1000,
        end: new Date(this.to).getTime() / 1000,
        search: this.searchText
      };
      if (this.type == "order") {
        params.filter_by = this.filter;
        let res = await ReportApiUrls.getLeisureBookingsReports(params);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "Дата создания",
            align: "start",
            sortable: false,
            value: "created_at",
            class: "table-title"
          },
          {
            text: "Дата реализации",
            align: "center",
            value: "check_in",
            class: "table-title"
          },
          {
            text: "Время",
            align: "center",
            value: "check_in_time",
            class: "table-title"
          },
          {
            text: "Источник",
            align: "center",
            value: "source_title",
            class: "table-title"
          },
          {
            text: "ФИО",
            align: "center",
            value: "full_name",
            class: "table-title"
          },
          {
            text: "Тип",
            align: "center",
            value: "tariff_type",
            class: "table-title"
          },
          {
            text: "Сумма",
            align: "center",
            value: "price",
            class: "table-title"
          },
          {
            text: "Номер телефона",
            align: "center",
            value: "phone_number",
            class: "table-title"
          },
          {
            text: "Сумма возврата",
            align: "center",
            value: "return_sum",
            class: "table-title"
          },
          {
            text: "Комментарий",
            align: "center",
            value: "comment",
            class: "table-title"
          }
        ];
        this.items.map(el => {
          el.created_at = moment(Number(el.created_at) * 1000).format(
            "DD.MM.YYYY"
          );
          el.check_in = moment(Number(el.check_in) * 1000).format("DD.MM.YYYY");
          el.check_in_time = el.check_in_time.substr(
            0,
            el.check_in_time.length - 3
          );
        });
      } else if (this.type == "tarif") {
        delete params["page[number]"];
        delete params["page[size]"];
        let res = await ReportApiUrls.getLeisureTarifReports(params);
        this.totalItems = res.data.length;
        this.loading = false;
        this.items = res.data;
        this.headers = [
          {
            text: "Время",
            align: "start",
            sortable: false,
            value: "start_time",
            class: "table-title"
          },
          {
            text: "Общее количество стоков",
            align: "center",
            value: "stock_number",
            class: "table-title"
          },
          {
            text: "Количество проданных стоков",
            align: "center",
            value: "sold_stocks_number",
            class: "table-title"
          },
          {
            text: "Поля",
            align: "center",
            value: "fields",
            class: "table-title"
          },
          {
            text: "Сумма",
            align: "center",
            value: "total",
            class: "table-title"
          }
        ];
        let itemFields = "";
        this.items.map(elem => {
          for (const [key, value] of Object.entries(elem)) {
            if (
              key != "start_time" &&
              key != "end_time" &&
              key != "stock_number" &&
              key != "sold_stocks_number" &&
              key != "total"
            ) {
              itemFields += key + "(" + value + "), ";
            }
          }
          itemFields = itemFields.substr(0, itemFields.length - 2);
          elem.fields = itemFields;
          elem.start_time = elem.start_time.substr(
            0,
            elem.start_time.length - 3
          );
          itemFields = "";
        });
      }
      this.getUpdTotal();
    },
    async getReport() {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10,
        start: new Date(this.from).getTime() / 1000,
        end: new Date(this.to).getTime() / 1000,
        search: this.searchText
      };
      if (this.type === "pay") {
        let res = await ReportApiUrls.getPayReports(params);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "Номер брони",
            align: "start",
            sortable: false,
            value: "booking",
            class: "table-title"
          },
          {
            text: "ФИО",
            align: "center",
            value: "full_name",
            class: "table-title"
          },
          {
            text: "Источники",
            align: "center",
            value: "source",
            class: "table-title"
          },
          {
            text: "Дата заезда",
            align: "center",
            value: "check_in",
            class: "table-title"
          },
          {
            text: "Дата выезда",
            align: "center",
            value: "check_out",
            class: "table-title"
          },
          {
            text: "Дата платежа",
            align: "center",
            value: "created_at",
            class: "table-title"
          },
          {
            text: "Категория номера",
            align: "center",
            value: "category_name",
            class: "table-title"
          },
          {
            text: "Номер",
            align: "center",
            value: "room_name",
            class: "table-title"
          },
          {
            text: "Тип операции",
            align: "center",
            value: "payment_type",
            class: "table-title"
          },
          {
            text: "Ответственный",
            align: "center",
            value: "responsible",
            class: "table-title"
          },
          {
            text: "Комментарии",
            align: "center",
            value: "comment",
            class: "table-title"
          },
          {
            text: "Оплачено",
            align: "center",
            value: "value",
            class: "table-title"
          },
          {
            text: "Долг",
            align: "center",
            value: "duty",
            class: "table-title"
          },
          {
            text: "Вид платежа",
            align: "center",
            value: "payment_method",
            class: "table-title"
          },
          {
            text: "Статус Брони",
            align: "center",
            value: "status",
            class: "table-title"
          }
        ];
        this.items.map(el => {
          el.created_at = moment(Number(el.created_at) * 1000).format(
            "DD.MM.YYYY"
          );
          el.check_in = moment(Number(el.check_in) * 1000).format("DD.MM.YYYY");
          el.check_out = moment(Number(el.check_out) * 1000).format(
            "DD.MM.YYYY"
          );
        });
      } else if (this.type === "client") {
        params.booking_status = this.status;
        let res = await ReportApiUrls.getClientReports(params);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "Номер заказа",
            align: "center",
            sortable: false,
            value: "id",
            class: "table-title"
          },
          {
            text: "ФИО",
            align: "center",
            value: "full_name",
            class: "table-title"
          },
          {
            text: "Категория",
            align: "center",
            value: "category_name",
            class: "table-title"
          },
          {
            text: "Номер",
            align: "center",
            value: "room_name",
            class: "table-title"
          },
          {
            text: "Создано",
            align: "center",
            value: "created_at",
            class: "table-title"
          },
          {
            text: "Заезд",
            align: "center",
            value: "check_in",
            class: "table-title"
          },
          {
            text: "Выезд",
            align: "center",
            value: "check_out",
            class: "table-title"
          },
          {
            text: "Ответственный",
            align: "center",
            value: "created_by",
            class: "table-title"
          },
          {
            text: "Сумма",
            align: "center",
            value: "total",
            class: "table-title"
          },
          {
            text: "Канал продаж",
            align: "center",
            value: "source_title",
            class: "table-title"
          },
          {
            text: "Номер телефона",
            align: "center",
            value: "phone_number",
            class: "table-title"
          },
          {
            text: "Статус Брони",
            align: "center",
            value: "status",
            class: "table-title"
          }
        ];
        this.items.map(el => {
          el.created_at = moment(Number(el.created_at) * 1000).format(
            "DD.MM.YYYY"
          );
          if (el.is_time) {
            el.check_in = moment(Number(el.check_in) * 1000).format(
              "DD.MM.YYYY HH:mm"
            );
            el.check_out = moment(Number(el.check_out) * 1000).format(
              "DD.MM.YYYY HH:mm"
            );
          } else {
            el.check_in = moment(Number(el.check_in) * 1000).format(
              "DD.MM.YYYY"
            );
            el.check_out = moment(Number(el.check_out) * 1000).format(
              "DD.MM.YYYY"
            );
          }
        });
      } else if (this.type === "order") {
        let res = await ReportApiUrls.getOrderReports(params);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "Номер заказа",
            align: "center",
            sortable: false,
            value: "id",
            class: "table-title"
          },
          {
            text: "ФИО",
            align: "center",
            value: "full_name",
            class: "table-title"
          },
          {
            text: "Источники",
            align: "center",
            value: "source_title",
            class: "table-title"
          },
          {
            text: "Категория",
            align: "center",
            value: "category_name",
            class: "table-title"
          },
          {
            text: "Номер",
            align: "center",
            value: "room_name",
            class: "table-title"
          },
          {
            text: "Заезд",
            align: "center",
            value: "check_in",
            class: "table-title"
          },
          {
            text: "Выезд",
            align: "center",
            value: "check_out",
            class: "table-title"
          },
          {
            text: "Дата создания",
            align: "center",
            value: "created_at",
            class: "table-title"
          },
          {
            text: "Ответственный",
            align: "center",
            value: "created_by",
            class: "table-title"
          },
          {
            text: "Статус",
            align: "center",
            value: "status",
            class: "table-title"
          },
          {
            text: "Дата отмены",
            align: "center",
            value: "canceled_at",
            class: "table-title"
          },
          {
            text: "Сумма",
            align: "center",
            value: "total",
            class: "table-title"
          }
        ];
        this.items.map(el => {
          el.created_at = moment(Number(el.created_at) * 1000).format(
            "DD.MM.YYYY"
          );
          if (el.is_time) {
            el.check_in = moment(Number(el.check_in) * 1000).format(
              "DD.MM.YYYY HH:mm"
            );
            el.check_out = moment(Number(el.check_out) * 1000).format(
              "DD.MM.YYYY HH:mm"
            );
          } else {
            el.check_in = moment(Number(el.check_in) * 1000).format(
              "DD.MM.YYYY"
            );
            el.check_out = moment(Number(el.check_out) * 1000).format(
              "DD.MM.YYYY"
            );
          }
          if (el.canceled_at > 0) {
            el.canceled_at = moment(Number(el.canceled_at) * 1000).format(
              "DD.MM.YYYY"
            );
          }
        });
      } else if (this.type === "object") {
        let res = await ReportApiUrls.getRoomReports(params);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "Номер",
            align: "start",
            sortable: false,
            value: "name",
            class: "table-title"
          },
          {
            text: "Количество основных мест",
            align: "center",
            sortable: false,
            value: "place_count",
            class: "table-title"
          },
          {
            text: "Количество доп мест",
            align: "center",
            sortable: false,
            value: "additional_place_count",
            class: "table-title"
          },
          {
            text: "Заезды",
            align: "center",
            value: "check_ins",
            class: "table-title"
          },
          {
            text: "Выезды",
            align: "center",
            value: "check_outs",
            class: "table-title"
          },
          {
            text: "Занято дней",
            align: "center",
            value: "busy_days",
            class: "table-title"
          },
          {
            text: "Свободно, дней",
            align: "center",
            value: "empty_days",
            class: "table-title"
          },
          {
            text: "Сумма",
            align: "center",
            value: "total",
            class: "table-title"
          },
          {
            text: "Количество дней",
            align: "center",
            value: "days",
            class: "table-title"
          }
        ];
      } else if (this.type === "object-zag") {
        let res = await ReportApiUrls.getRoomZagReports(params);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "Номер",
            align: "start",
            sortable: false,
            value: "name",
            class: "table-title"
          },
          {
            text: "Количество мест",
            align: "center",
            sortable: false,
            value: "place_count",
            class: "table-title"
          },
          {
            text: "Заезды",
            align: "center",
            value: "check_ins",
            class: "table-title"
          },
          {
            text: "Выезды",
            align: "center",
            value: "check_outs",
            class: "table-title"
          },
          {
            text: "Занято дней",
            align: "center",
            value: "busy_days",
            class: "table-title"
          },
          {
            text: "Свободно, дней",
            align: "center",
            value: "empty_days",
            class: "table-title"
          },
          {
            text: "Загрузка в %",
            align: "center",
            value: "workload_in_percent",
            class: "table-title"
          }
        ];
      } else if (this.type === "source") {
        params.payment_status = this.payStatus;
        let res = await ReportApiUrls.getSourceReports(params);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "Канал продаж",
            align: "start",
            sortable: false,
            value: "source_title",
            class: "table-title"
          },
          {
            text: "Номер заказа",
            align: "center",
            value: "id",
            class: "table-title"
          },
          {
            text: "Категория",
            align: "center",
            value: "category_name",
            class: "table-title"
          },
          {
            text: "Сумма",
            align: "center",
            value: "total",
            class: "table-title"
          },
          {
            text: "Комиссия",
            align: "center",
            value: "commission",
            class: "table-title"
          },
          {
            text: "Статус оплаты",
            align: "center",
            value: "payment_status",
            class: "table-title"
          },
          {
            text: "Статус брони",
            align: "center",
            value: "booking_status",
            class: "table-title"
          }
        ];
      } else if (this.type === "service") {
        let res = await ReportApiUrls.getServiceReports(params);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "Услуга",
            align: "start",
            sortable: false,
            value: "service_name",
            class: "table-title"
          },
          {
            text: "Количество",
            align: "center",
            value: "quantity",
            class: "table-title"
          },
          {
            text: "ФИО",
            align: "center",
            value: "full_name",
            class: "table-title"
          },
          {
            text: "Дата создания",
            align: "center",
            value: "created_at",
            class: "table-title"
          },
          {
            text: "Номер",
            align: "center",
            value: "room_name",
            class: "table-title"
          },
          {
            text: "Сумма",
            align: "center",
            value: "price",
            class: "table-title"
          }
        ];
        this.items.map(el => {
          el.created_at = moment(Number(el.created_at) * 1000).format(
            "DD.MM.YYYY"
          );
        });
      } else if (this.type === "kitchen") {
        let res = await ReportApiUrls.getKitchenReports(params);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "ФИО",
            align: "start",
            sortable: false,
            value: "full_name",
            class: "table-title"
          },
          {
            text: "Комната",
            align: "start",
            sortable: false,
            value: "name",
            class: "table-title"
          },
          {
            text: "Завтрак взрослый",
            align: "center",
            value: "breakfast_adult",
            class: "table-title"
          },
          {
            text: "Завтрак детский",
            align: "center",
            value: "breakfast_child",
            class: "table-title"
          },
          {
            text: "Обед взрослый",
            align: "center",
            value: "lunch_adult",
            class: "table-title"
          },
          {
            text: "Обед детский",
            align: "center",
            value: "lunch_child",
            class: "table-title"
          },
          {
            text: "Ужин взрослый",
            align: "center",
            value: "supper_adult",
            class: "table-title"
          },
          {
            text: "Ужин детский",
            align: "center",
            value: "supper_child",
            class: "table-title"
          },
          {
            text: "Заезд",
            align: "center",
            value: "check_in",
            class: "table-title"
          },
          {
            text: "Выезд",
            align: "center",
            value: "check_out",
            class: "table-title"
          }
        ];
        this.items.forEach(e => {
          e.check_in = moment(e.check_in * 1000).format("DD.MM.YYYY HH:mm");
          e.check_out = moment(e.check_out * 1000).format("DD.MM.YYYY HH:mm");
        });
      } else if (this.type === "key") {
        let res = await ReportApiUrls.getKeyReports(params);
        console.log(res);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "Заезд",
            align: "center",
            value: "check_in",
            class: "table-title"
          },
          {
            text: "Выезд",
            align: "center",
            value: "check_out",
            class: "table-title"
          },
          {
            text: "ФИО",
            align: "start",
            sortable: false,
            value: "full_name",
            class: "table-title"
          },
          {
            text: "Пользователь заселил",
            align: "start",
            sortable: false,
            value: "check_in_user",
            class: "table-title"
          },
          {
            text: "Пользователь выселил",
            align: "start",
            sortable: false,
            value: "check_out_user",
            class: "table-title"
          },
          {
            text: "Номер",
            align: "start",
            sortable: false,
            value: "room_name",
            class: "table-title"
          },
          {
            text: "Статус",
            align: "start",
            sortable: false,
            value: "booking_status",
            class: "table-title"
          },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "",
            class: "table-title"
          },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "",
            class: "table-title"
          },
          {
            text: "Дата прибытия",
            align: "center",
            value: "key_check_in",
            class: "table-title"
          },
          {
            text: "Дата выбытия",
            align: "center",
            value: "key_check_out",
            class: "table-title"
          },
          {
            text: "Комната",
            align: "start",
            sortable: false,
            value: "key_room_name",
            class: "table-title"
          },
          {
            text: "Статус",
            align: "start",
            sortable: false,
            value: "key_status",
            class: "table-title"
          }
        ];
        this.items.forEach(e => {
          e.check_in = moment(e.check_in * 1000).format("DD.MM.YYYY HH:mm");
          e.check_out = moment(e.check_out * 1000).format("DD.MM.YYYY HH:mm");
          e.key_check_in = moment(e.key_check_in * 1000).format(
            "DD.MM.YYYY HH:mm"
          );
          e.key_check_out = moment(e.key_check_out * 1000).format(
            "DD.MM.YYYY HH:mm"
          );
        });
      } else if (this.type === "talon") {
        delete params.search;
        let res = await ReportApiUrls.getTalonReports(params);
        this.totalItems = res.meta.pagination.count;
        this.loading = false;
        this.items = res.results;
        this.headers = [
          {
            text: "Дата питания",
            align: "center",
            value: "day",
            class: "table-title"
          },
          {
            text: "Номер талона",
            align: "center",
            value: "number",
            class: "table-title"
          },
          {
            text: "Комната",
            align: "start",
            sortable: false,
            value: "room_name",
            class: "table-title"
          },
          {
            text: "ФИО",
            align: "start",
            sortable: false,
            value: "full_name",
            class: "table-title"
          },
          {
            text: "Цена оплаты",
            align: "start",
            sortable: false,
            value: "price",
            class: "table-title"
          },
          {
            text: "Ответственный",
            align: "start",
            sortable: false,
            value: "responsible",
            class: "table-title"
          },
          {
            text: "Дата оплаты",
            align: "start",
            sortable: false,
            value: "payment_datetime",
            class: "table-title"
          },
          {
            text: "Дата и время выдачи талонов",
            align: "start",
            sortable: false,
            value: "download_datetime",
            class: "table-title"
          },
          {
            text: "Количество выдачи талонов",
            align: "start",
            sortable: false,
            value: "download_count",
            class: "table-title"
          },
          {
            text: "Взрослый или детский",
            align: "center",
            value: "adult_or_children",
            class: "table-title"
          },
          {
            text: "Вид питания",
            align: "center",
            value: "food_type",
            class: "table-title"
          },
          {
            text: "Статус",
            align: "start",
            sortable: false,
            value: "status",
            class: "table-title"
          }
        ];
        this.items.forEach(e => {
          e.day = moment(e.day * 1000).format("DD.MM.YYYY");
          e.payment_datetime =
            e.payment_datetime &&
            moment(e.payment_datetime * 1000).format("DD.MM.YYYY HH:mm");
          e.download_datetime =
            e.download_datetime &&
            moment(e.download_datetime * 1000).format("DD.MM.YYYY HH:mm");
        });
      }
      this.getTotal();
    },
    saveFrom() {
      if (this.object_type == "is_leisure") {
        this.getUpdReport();
      } else {
        this.getReport();
      }
      this.fromDateDialog = false;
      this.$refs.from.save(this.from);
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 480) {

  //уменьшить в адаптивке иконку
  #details ::v-deep .pa-7 {
    padding: 5vw !important;
  }
}
</style>
